$platform-name: 'allevialimited';

$brand-primary: #0971CE;
$brand-secondary: #FB3659;
$donate-colour: $brand-secondary;
$grey-lightest: #f3f3f3;
$grey-dark: $brand-primary;
$black: #022F40;

// layout
$gap-width: 40px;
$site-bleed: 20px;
$border-radius: 0;

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Buttons
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;
$btn-hover-style: lighten;
$donate-btn-colour: #fff;
$btn-text-colour: #fff;
$btn-border-radius: 6px;

// Forms
$input-border-radius: 6px;
$input-padding-y: .75rem;
$input-padding-x: 1rem;

// Logo
$logo: 'logo.svg';
$logo-retina: $logo;
$logo-width: 190px;
$logo-height: 125px;
$logo-mobile-width: $logo-width * .5;
$logo-mobile-height: $logo-height * .5;

// Typography
$font-family-base: 'Open sans', sans-serif;
$headings-font-family: 'Open sans', sans-serif;
$headings-colour: #0C487D;
$font-size-base: 1.1rem;
$font-size-h1: 2.2em;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

// Links
// $link-colour: $brand-secondary;

// Header
$header-background-colour: #fff;
$tagline-enabled: true;
$tagline-font-size: 1em;
$tagline-font-weight: normal;
// $header-search-enabled: false;

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: #fff;


// Social
$social-icons-background-colour: transparent;
$social-icons-header-colour: $brand-primary;

// Menu admin - Can't add margin to this?
$menu-admin-enabled: false;
// $menu-admin-background-colour: transparent;
// $menu-admin-absolute: true;
// $menu-admin-background-colour: #fff;
// $menu-admin-link-colour: $brand-primary;
// $menu-admin-manager-link-enabled: false;
// $menu-admin-login-link-enabled: false;
// $menu-admin-enabled: false;

// Search
$header-search-input-margin-right: -40px;
$header-search-input-background-colour: $grey-lightest;
$header-search-input-border-colour: #ddd;
$header-search-button-icon-colour: $brand-primary;
$header-search-input-border-width: 1px;
$header-search-button-width: 30px;
$header-search-button-height: 46px;
$header-search-button-background-colour: transparent;
$header-search-input-border-radius: 6px;
$header-search-input-padding: 10px 10px;
$nav-search-button-icon-colour: $white;

// Navigation
$nav-normal-max-width: 100%;
// $nav-normal-contents-max-width:
$nav-background-colour: $brand-primary;
$nav-normal-align-items: left;
$nav-normal-margin-bottom: 0;
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: #fff;
$nav-top-level-item-hover-background-colour: darken($brand-primary, 5%);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-item-font-size: 0.85em;

// Burger button
$burger-btn-background-colour: $brand-secondary;
$burger-btn-text-colour: #fff;
//$burger-btn-bar-breakpoint: 375px;

// Submenus
$nav-submenu-background-colour: darken($brand-primary, 5%);
$nav-submenu-item-colour: #fff;
$nav-submenu-item-font-size: 0.85em;

// Carousel
$carousel-max-width: 100%;
$carousel-details-padding: $spacer * 3;
$carousel-details-background-colour: $brand-primary;
$carousel-details-max-width: 550px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-details-margin-x: 0rem;
$carousel-details-margin-y: 0rem;
$carousel-read-more-background-colour: $brand-secondary;
$carousel-details-position-y: bottom;
$carousel-controls-button-border-radius: 0;
$carousel-details-border-radius: 0 10px 0 0;

//WHITE VERSION
// $carousel-max-width: 100%;
// $carousel-details-padding: $spacer * 3;
// $carousel-details-background-colour: #fff;
// $carousel-details-max-width: 600px;
// $carousel-heading-colour: $brand-primary;
// $carousel-summary-colour: $black;
// $carousel-details-margin-x: 3rem;
// $carousel-details-margin-y: 3rem;
// $carousel-read-more-background-colour: $brand-secondary;
// $carousel-details-position-y: bottom;
// $carousel-controls-button-border-radius: 0;
// $carousel-details-border-radius: 10px;

// Home intro
$home-intro-max-width: 900px;
$home-intro-margin-top: 3rem;
$home-intro-background-colour: #fff;
$home-intro-padding-y: $spacer;

// Cards
$card-border: 0;
$card-border-radius: 10px;
$card-box-shadow: 0px 0px 10px #dddddd;
$card-heading-font-size: $font-size-h5;
$card-heading-colour: $brand-primary;
$card-details-padding: $spacer * 2;
$card-hover-image-scale: 1.1;
$card-hover-image-opacity: 1;

// Home features
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations
$home-features-cards-entry-animation-stagger: true !default;
$home-features-cards-entry-animation-stagger-count: 3 !default;

// Impact stats
$impact-stats-padding-y: $spacer * 4;
$impact-stats-background-colour: #0C487D;
//$impact-stats-heading-text-align: left;
$impact-stats-heading-margin-bottom: $spacer * 1;
$impact-stats-background-image: "statsbg.jpg";

// Home Quick giving panel
$home-quick-giving-background-colour: $brand-secondary;
$home-quick-giving-donation-amount-background-colour: #FFA3B3;
$home-quick-giving-donate-btn-background-colour: #fff;
$home-quick-giving-donate-btn-colour: $brand-secondary;
$home-quick-giving-heading-colour: #fff;

// Home feeds
$home-feeds-background-colour: #fff;
$home-feeds-padding-top: $spacer * 2;
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Sidebars - off
$sidebar-enabled: false;
$post-content-max-width: 900px;
$sidebar-layout: below;

// Footer
$footer-background-colour: darken($brand-primary, 10%);
$footer-link-colour: #fff;
$social-icons-footer-colour: #fff;
$footer-boxes-count: 3;
$footer-prefab: 5;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-text-align: left;
$newsletter-button-height: 50px;

// Pages
$blockquote-background-colour: $grey-lightest;
$blockquote-colour: $brand-secondary;
$blockquote-text-align: left;

// Donation amounts
$donation-amount-min-width: 220px;
